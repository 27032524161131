<template>
  <static-fullscreen-card>
    <template v-slot:header></template>
    <template v-slot:actions>
      <v-btn color="primary" @click="fetchData()"><btn-title icon="fas fa-sync-alt">Обновить</btn-title> </v-btn>
      <v-btn color="primary" @click="createNew()" title="Создать элемент"><btn-title icon="far fa-plus-square">Создать</btn-title></v-btn></template
    >
    <portal to="v-main">
      <edit-dialog v-if="false" v-model="showEditDialog" :id="idEdit" :model="m.form" :api="url"></edit-dialog>
      <s-edit-form v-model="showEditDialog" v-if="showEditDialog" :api="url" :m="m" :id="idEdit" :configForm="{ type: 'default' }" :opt="{ closeAfterSave: false }" />
    </portal>
    <a-table-f-api ref="table" :api="url" :dataTable="dataTable" :model="m.list" :useQuery="false" @click="onClickRow($event)" />
  </static-fullscreen-card>
</template>

<script>
export default {
  components: {
    editDialog: () => import("./dialogs/apiConfigDialog"),
  },
  data() {
    return {
      idEdit: 0,
      showEditDialog: false,
      dataTable: [],
      title: "",
      m: {
        title: "",
        isDocument: false,
        api: "/report_sql/config",
        list: [
          { name: "id", title: "#", type: "id", sortable: true, width: 150 },
          { name: "name", title: "Наименование", type: "string", sortable: true, width: 450 },
        ],
        form: [
          { name: "name", title: "Наименование", type: "string", validator: ["req"] },
          { name: "sql", title: "SQL запрос", type: "text", validator: ["req"] },
          { name: "status", title: "Статус", validator: ["req"], type: "number", default: 1 },
        ],
        config: { default: { fields: "name,sql,status", fieldsForm: "name,sql,status", fieldsRO: "" } },
      },
      url: "/report_sql/config",
      defaults: {},
    };
  },
  created() {
    this.$root.title = "Информация";
    this.fetchData();
  },
  watch: {
    showEditDialog() {
      if (!this.showEditDialog) {
        this.$refs.table.updateData();
      }
    },
  },
  methods: {
    async fetchData() {
      let data = [];
      this.loading = true;
      let response = await this.$axios.get(this.url);
      let obj = response.data.data || {};
      this.loading = !true;
      console.log("getData", obj, response);
      if (true) {
        for (const key in obj) {
          if (Object.hasOwnProperty.call(obj, key)) {
            data.push({ id: key, name: key, sqlText: obj[key] });
          }
        }
      }
      let res = data.sort((a, b) => {
        return isNaN(a.id) ? (a.id > b.id) - (b.id > a.id) : parseFloat(a.id) > parseFloat(b.id);
      });
      this.dataTable = res;
    },
    onClickRow(d) {
      this.showEditDialogFun(d.row.id);
    },
    createNew() {
      this.showEditDialogFun(0);
    },
    showEditDialogFun(id) {
      this.idEdit = id;
      this.showEditDialog = true;
    },
  },
};
</script>
